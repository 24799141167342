/* eslint-disable camelcase */
import * as React from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'
import { useHistory } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import { Form as AntForm, Input, Button, notification } from 'antd'
import { Helmet } from 'react-helmet'
import { isEmpty, toLower, set } from 'lodash'
import { DEBOUNCE, tryParseQueryString } from 'helpers/utils'
import { setStorageItem, getStorageItem } from 'helpers/localStorage'
import { showError, showValidationError } from 'helpers/errors'
import { getSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { LIGHT_GREY } from 'options/colors'
import { INACTIVITY_SESSION_KEY } from 'options/auth'
import { sendClientLog } from 'helpers/auth'
import { t } from 'helpers/i18n'
import { stopEvent } from 'helpers/events'
import Modal from 'elements/Modal'
import { getTheme } from 'helpers/themes'
import Icon from 'elements/Icon'
import ForgotPassword from 'containers/ForgotPassword'
import ExternalApproval from 'containers/ExternalApproval'

const theme = getTheme()

const getStorageKey = () => 'login.form'

const Container = styled.div`
  height: 100vh;
  position: relative;
  display: flex;
  background: url(${theme.login.background}) no-repeat center;

  @media screen and (min-width: 768px) {
    display: block;
  }

  .ant-btn-link {
    color: ${theme.login.primaryColor};
  }

  .ant-input:focus,
  .ant-input:hover {
    border-color: ${theme.login.primaryColor};
  }

  .ant-input-affix-wrapper:hover {
    border-color: ${theme.login.primaryColor};
  }
`

const Form = styled(AntForm)`
  width: 336px;
  position: relative;
  margin: auto;

  input {
    color: #666;
    background-color: transparent;

    &::-webkit-input-placeholder {
      color: #bbb;
    }

    &::-moz-placeholder {
      color: #bbb;
    }

    &:-ms-input-placeholder {
      color: #bbb;
    }

    &:-moz-placeholder {
      color: #bbb;
    }
  }

  @media screen and (min-width: 768px) {
    position: absolute;
    left: ${theme.login.formPosition};
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .ant-input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid rgb(217, 217, 217);
    padding-left: 40px !important;

    &:focus {
      box-shadow: none;
      border-bottom: 1px solid ${theme.login.primaryColor};
    }
  }

  .ant-form-explain {
    padding-left: 40px;
  }
`

const Buttons = styled(Form.Item)`
  padding-top: 24px;
  text-align: center;

  .ant-btn-primary {
    width: 297px;
    height: 54px;
    padding: 12px 0;
    font-weight: 600;
    border-color: ${theme.login.primaryColor};
    background-color: ${theme.login.primaryColor};
  }
`

function Component(props) {
  const history = useHistory()

  const {
    id: startupEntityId,
    redirect: startupEntityName,
    reject,
    approve,
    acknowledge,
    customerid,
    customerId,
    customerID,
    customer_id,
  } = tryParseQueryString(
    window.location.hash ? tryParseQueryString(window.location.hash).state : window.location.search
  )

  const injectedTenant = customerid || customerId || customerID || customer_id

  const [state, updateState] = useImmer({
    ...getStorageItem(getStorageKey(), {}),
    ...(injectedTenant ? { tenant: injectedTenant } : {}),
  })

  function setState(path, value) {
    updateState((draft) => {
      set(draft, path, value)
    })
  }

  function handleSubmit(e) {
    stopEvent(e)

    notification.destroy()

    props.form.validateFields(async (errors, values) => {
      if (!isEmpty(errors)) {
        showValidationError()
        return
      }

      if (state.tenantInfo?.ssoEnabled) {
        window.location.href = state.tenantInfo?.authenticationRedirectUrl.replace(
          /redirect_uri=\(redirect_uri\)/,
          `redirect_uri=${encodeURIComponent(`${window.location.origin}/login`)}${
            window.location.search ? `&state=${encodeURIComponent(window.location.search.replace(/^\?/, ''))}` : ''
          }`
        )
      } else {
        try {
          setState('loading', true)

          const startPage = await props.login({
            ...values,
            tenant: toLower(values.tenant),
            startupEntityName,
            startupEntityId,
          })

          await sendClientLog('Success', 'Login', { startPage })

          history.push(startPage)
        } catch (error) {
          showError({ error })
          setState('loading', false)
        }
      }
    })
  }

  React.useEffect(() => {
    if (getSessionItem(INACTIVITY_SESSION_KEY)) {
      Modal.info({
        title: t('inactivityWarningMessage'),
        content: t('inactivityWarningDescription'),
      })

      removeSessionItem(INACTIVITY_SESSION_KEY)
    }
  }, [])

  React.useEffect(() => {
    setStorageItem(getStorageKey(), { tenant: toLower(state.tenant) })
  }, [state.tenant])

  const fetchTenantInfo = useDebouncedCallback(async (subdomain) => {
    try {
      const response = await props.getTenantInfo({ subdomain })
      setState('tenantInfo', response.value.data)
    } catch (error) {
      console.warn(error)
      setState('tenantInfo', null)
    }
  }, DEBOUNCE)

  React.useEffect(() => {
    if (state.tenant) {
      fetchTenantInfo(toLower(state.tenant))
    } else {
      setState('tenantInfo', null)
      fetchTenantInfo.cancel()
    }
  }, [state.tenant])

  React.useEffect(() => {
    async function tryLoginSso() {
      const { access_token, id_token } = tryParseQueryString(window.location.hash)

      if (access_token && id_token) {
        try {
          setState('loading', true)

          const startPage = await props.login({
            tenant: toLower(state.tenant),
            idToken: id_token,
            accessToken: access_token,
            startupEntityName,
            startupEntityId,
          })

          await sendClientLog('Success', 'Login SSO', { startPage })

          history.push(startPage)
        } catch (error) {
          showError({ error })
          setState('loading', false)
        }
      }
    }

    if (window.location.hash) {
      tryLoginSso()
    }
  }, [window.location.hash])

  return (
    <Container>
      <Helmet>
        <title>TRMS</title>
      </Helmet>
      <Form layout="vertical" colon={false} onSubmit={handleSubmit}>
        <Form.Item>
          {props.form.getFieldDecorator('tenant', {
            rules: [{ required: true, message: t('requiredField') }],
            initialValue: state.tenant,
          })(
            <Input
              name="tenant"
              prefix={<Icon type="Store" color={LIGHT_GREY} />}
              placeholder={t('customerId')}
              autoFocus={!state.tenant}
              onChange={(e) => setState('tenant', e.target.value)}
              autoCorrect="off"
              autoCapitalize="none"
              disabled={state.loading}
              allowClear
            />
          )}
        </Form.Item>
        {!state.tenantInfo?.ssoEnabled && (
          <Form.Item>
            {props.form.getFieldDecorator('userName', {
              rules: [{ required: true, message: t('requiredField') }],
            })(
              <Input
                name="userName"
                prefix={<Icon type="Person" color={LIGHT_GREY} />}
                placeholder={t('userId')}
                autoFocus={Boolean(state.tenant)}
                onChange={(e) => setState('userName', e.target.value)}
                autoCorrect="off"
                autoCapitalize="none"
                disabled={state.loading}
                allowClear
              />
            )}
          </Form.Item>
        )}
        {!state.tenantInfo?.ssoEnabled && (
          <Form.Item>
            {props.form.getFieldDecorator('password', {
              rules: [{ required: true, message: t('requiredField') }],
            })(
              <Input.Password
                name="password"
                type="password"
                prefix={<Icon type="Lock" color={LIGHT_GREY} />}
                placeholder={t('password')}
                onChange={(e) => setState('password', e.target.value)}
                disabled={state.loading}
              />
            )}
          </Form.Item>
        )}
        <Buttons>
          <Button type="primary" htmlType="submit" loading={state.loading} block>
            {state.tenantInfo?.ssoEnabled ? t('loginUsingSSO') : t('login')}
          </Button>
        </Buttons>
        {!state.tenantInfo?.ssoEnabled && (
          <div className="text-center">
            <ForgotPassword
              initialValues={{
                tenant: props.form.getFieldValue('tenant'),
                userName: props.form.getFieldValue('userName'),
              }}
            />
          </div>
        )}
      </Form>
      {!(isEmpty(approve) && isEmpty(reject) && isEmpty(acknowledge)) && (
        <ExternalApproval approve={approve} reject={reject} acknowledge={acknowledge} />
      )}
    </Container>
  )
}

export default Form.create()(Component)
