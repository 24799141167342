import { Button, Tooltip, Input, Popover, Form, message } from 'antd'
import { isNil, isEmpty, get } from 'lodash'
import cx from 'clsx'
import { Link } from 'react-router-dom'
import produce from 'immer'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { showError, showClientNotifications } from 'helpers/errors'
import { encodeState } from 'helpers/utils'
import { getUserPermission, allowPricing } from 'helpers/auth'
import { t, toLocaleCurrency } from 'helpers/i18n'
import { RED, DARK_GREEN } from 'options/colors'
import { createY2KNullTableCellComponent } from 'helpers/listViews'
import { Emitter } from 'helpers/events'
import { FORM_SUBMIT_EVENT } from 'options/events'
import linkTargets from 'options/linkTargets'
import Modal from 'elements/Modal'
import InputNumber from 'elements/InputNumber'
import Icon from 'elements/Icon'
import SelectSupplier from 'containers/Suppliers/Select'
import PrintLabel from 'containers/PrintLabel'
import Select, { Option, getOptionProps } from 'elements/Select'
import SelectOverstockItemInventory from 'containers/Inventory/SelectOverstockItemInventory'
import TransferInventory from 'containers/Inventory/Transfer'
import TransferOverstockInventory from 'containers/OverstockInventory/Transfer'
import Filter from 'containers/Inventory/Filter'
import { getCountryName } from 'helpers/countries'

export const createOnHandTableCellComponent = (fieldName) => (self, item) =>
  item.lockedForCycleCount ? (
    <Input
      value={get(item, fieldName)}
      suffix={
        <Tooltip title={t('onhandQuantitiesLockedForCycleCount')}>
          <Icon type="Lock" color={RED} />
        </Tooltip>
      }
      disabled
    />
  ) : (
    <InputNumber
      className={cx({
        'form-field-highlight':
          get(
            self.props.items.find((one) => one.id === item.id),
            fieldName
          ) !==
          get(
            self.state.items.find((one) => one.id === item.id),
            fieldName
          ),
      })}
      value={get(item, fieldName)}
      onChange={(value) =>
        self.setState(
          produce((draft) => {
            draft.items.find((one) => one.id === item.id)[fieldName] = value
          })
        )
      }
      disabled={getUserPermission('Inventory') !== 'Edit'}
      min={0}
    />
  )

export const createOnOrderTableCellComponent = (overstockInventory) => (self, item) => (
  <div>
    {item.onOrder > 0 ? (
      <>
        <Link
          to={`/orders/orders?${encodeState({
            search: item.barcode,
            searchFields: ['barcode'],
            searchType: 'Contains',
            showFilter: true,
            filterDto: {
              dateRange: {
                dateRangeType: 'Custom',
                customStartDate: item.onOrderStartDate,
                customEndDate: new Date().toJSON(),
              },
              locationIds: !overstockInventory && item.locationId ? [item.locationId] : [],
              locationGroupIds:
                overstockInventory && self.state.filterDto?.locationGroupId
                  ? [self.state.filterDto.locationGroupId]
                  : [],
              statusFilter: 'AllOpenOrders',
            },
          })}`}
          className="mr-6"
        >
          {item.onOrder}
        </Link>
        <Popover
          title={t('onOrderWithinPast12Months')}
          content={<span dangerouslySetInnerHTML={{ __html: item.onOrderInfo }} />}
        >
          <span
            className="align-middle color-blue"
            style={{ visibility: isEmpty(item.onOrderInfo) ? 'hidden' : 'visible' }}
          >
            <Icon type="Search" />
          </span>
        </Popover>
      </>
    ) : (
      <span className="mr-24">{item.onOrder}</span>
    )}
  </div>
)

export default createListViewComponent({
  entityName: entityNames.inventory,
  filterTemplateType: 'Inventory',
  createButtonTextId: 'addToInventory',
  primaryLinkTarget: 'inventoryRecord',
  initialFilterDto: (self) => ({
    cartType: 'All',
    dateRange: { dateRangeType: 'YearToDate' },
  }),
  extraRowActions: (self) => {
    const userCanEditInventory = getUserPermission('Inventory') === 'Edit'
    const hasUnsavedChanges = self.hasUnsavedChanges()
    const selectedRowItems = self.state.selectedRowKeys.map((each) => self.state.items.find((one) => one.id === each))

    return (
      <>
        <PrintLabel type="Inventory" items={selectedRowItems} parentHasUnsavedChanges={hasUnsavedChanges} />
        {userCanEditInventory && (
          <>
            <Button
              disabled={self.state.selectedRowKeys.length !== 1}
              onClick={() => {
                if (hasUnsavedChanges) {
                  message.error(t('saveChangesFirst'))
                } else if (selectedRowItems[0].lockedForCycleCount) {
                  message.error(t('errorLockedForCycleCount'))
                } else {
                  self.setState({ transferInventoryVisible: true })
                }
              }}
            >
              <Icon type="SwapHoriz" />
              {t('transfer')}
            </Button>
            <Modal
              title={`${t('transferInventory')} - ${selectedRowItems[0]?.barcode}`}
              visible={self.state.transferInventoryVisible}
              onCancel={() => self.setState({ transferInventoryVisible: false })}
              okText={t('transfer')}
              onOk={() => Emitter.emit(FORM_SUBMIT_EVENT)}
              okButtonProps={{ loading: self.state.transferInventoryLoading }}
              width={750}
            >
              <TransferInventory
                parentRecord={selectedRowItems[0]}
                onSave={(pending) => self.promiseState({ transferInventoryLoading: pending })}
                onSaveAndClose={() => self.setState({ transferInventoryVisible: false }, self.fetchItems)}
                onCancel={() => self.setState({ transferInventoryVisible: false })}
              />
            </Modal>
            <Button
              onClick={() =>
                hasUnsavedChanges
                  ? message.error(t('saveChangesFirst'))
                  : self.setState({ setPreferredSupplierVisible: true })
              }
              disabled={isEmpty(self.state.selectedRowKeys)}
            >
              <Icon type="LocalShipping" />
              {t('setPreferredSupplier')}
            </Button>
            <Modal
              title={`${t('setPreferredSupplier')} (${self.state.selectedRowKeys.length} ${t('items')})`}
              visible={self.state.setPreferredSupplierVisible}
              okText={t('update')}
              okButtonProps={{
                disabled: isNil(self.state.newSupplierId),
                loading: self.state.setPreferredSupplierLoading,
              }}
              onOk={async () => {
                try {
                  self.setState({ setPreferredSupplierLoading: true })

                  const response = await self.props.setPreferredSupplier({
                    inventoryIds: self.state.selectedRowKeys,
                    newSupplierId: self.state.newSupplierId,
                  })

                  showClientNotifications({ response })

                  self.setState({
                    setPreferredSupplierVisible: false,
                    newSupplierId: undefined,
                  })

                  self.fetchItems()
                } catch (error) {
                  showError({ error })
                } finally {
                  self.setState({ setPreferredSupplierLoading: false })
                }
              }}
              cancelText={t('close')}
              onCancel={() =>
                self.setState({
                  setPreferredSupplierVisible: false,
                  newSupplierId: undefined,
                })
              }
              width={992}
            >
              <SelectSupplier
                onSelect={(values) => self.setState({ newSupplierId: get(values, '[0].id') })}
                filterProps={{ disabled: true }}
              />
            </Modal>
          </>
        )}
        {getUserPermission('Reports') !== 'No' && (
          <Button
            disabled={self.state.selectedRowKeys.length !== 1}
            onClick={() => {
              const selectedItem = self.state.items.find((one) => one.id === self.state.selectedRowKeys[0])
              self.props.history.push(
                `/inventory/reports/itemHistoryReport?${encodeState({
                  showFilter: true,
                  filterDto: {
                    barcode: selectedItem?.barcode,
                    dateRange: { dateRangeType: 'YearToDate' },
                    locationIds: [selectedItem?.locationId],
                  },
                })}`
              )
            }}
          >
            <Icon type="InsertChart" />
            {t('itemHistoryReport')}
          </Button>
        )}
        {userCanEditInventory && self.props.tenantGroupIsCardinal && (
          <>
            <Button
              onClick={async () => {
                if (hasUnsavedChanges) {
                  message.error(t('saveChangesFirst'))
                } else {
                  try {
                    const response = await self.props.getLocations()

                    self.setState({
                      duplicateInventoryLocations: response.value.data.items,
                      duplicateInventoryVisible: true,
                      duplicateInventoryFromLocationId: undefined,
                      duplicateInventoryToLocationId: undefined,
                    })
                  } catch (error) {
                    showError({ error })
                  }
                }
              }}
            >
              <Icon type="ContentCopy" />
              {t('duplicateInventory')}
            </Button>
            <Modal
              title={t('duplicateInventory')}
              visible={self.state.duplicateInventoryVisible}
              okText={t('duplicate')}
              okButtonProps={{
                disabled:
                  isNil(self.state.duplicateInventoryFromLocationId) ||
                  isNil(self.state.duplicateInventoryToLocationId) ||
                  self.state.duplicateInventoryFromLocationId === self.state.duplicateInventoryToLocationId,
                loading: self.state.duplicateInventoryLoading,
              }}
              onOk={() =>
                Modal.confirm({
                  autoFocusButton: 'ok',
                  title: t('confirmDuplicateInventory'),
                  okType: 'primary',
                  okText: t('duplicate'),
                  cancelText: t('cancel'),
                  onOk: async () => {
                    try {
                      self.setState({ duplicateInventoryLoading: true })

                      const response = await self.props.duplicateInventory({
                        fromLocationId: self.state.duplicateInventoryFromLocationId,
                        toLocationId: self.state.duplicateInventoryToLocationId,
                      })

                      showClientNotifications({ response })

                      self.setState({
                        duplicateInventoryVisible: false,
                        duplicateInventoryFromLocationId: undefined,
                        duplicateInventoryToLocationId: undefined,
                      })

                      self.fetchItems()
                    } catch (error) {
                      showError({ error })
                    } finally {
                      self.setState({ duplicateInventoryLoading: false })
                    }
                  },
                })
              }
              cancelText={t('close')}
              onCancel={() =>
                self.setState({
                  duplicateInventoryVisible: false,
                  duplicateInventoryFromLocationId: undefined,
                  duplicateInventoryToLocationId: undefined,
                })
              }
            >
              <Form.Item label={t('fromLocation')} colon={false} required>
                <Select
                  onChange={(value) => self.setState({ duplicateInventoryFromLocationId: value })}
                  placeholder={t('select')}
                  value={self.state.duplicateInventoryFromLocationId}
                >
                  {(self.state.duplicateInventoryLocations ?? [])
                    .filter((each) => each.id !== self.state.duplicateInventoryToLocationId)
                    .map((each) => (
                      <Option key={each.id} value={each.id}>
                        <span {...getOptionProps(each)}>{each.displayName}</span>
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label={t('toLocation')} colon={false} required>
                <Select
                  onChange={(value) => self.setState({ duplicateInventoryToLocationId: value })}
                  placeholder={t('select')}
                  value={self.state.duplicateInventoryToLocationId}
                >
                  {(self.state.duplicateInventoryLocations ?? [])
                    .filter((each) => each.id !== self.state.duplicateInventoryFromLocationId)
                    .map((each) => (
                      <Option key={each.id} value={each.id}>
                        <span {...getOptionProps(each)}>{each.displayName}</span>
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Modal>
          </>
        )}
      </>
    )
  },
  allowInlineEditing: (self) => getUserPermission('Inventory') === 'Edit',
  tableSummary: (self) =>
    allowPricing()
      ? `${t('totalValue:')} ${[self.props.list?.onHandValue ?? 0, self.props.list?.onHand2Value ?? 0]
          .map(toLocaleCurrency)
          .join(' / ')}`
      : '',
  tableCellComponents: {
    barcode: (self, item, column, text) => (
      <Tooltip title={item.lockedForCycleCount ? t('onhandQuantitiesLockedForCycleCount') : undefined}>
        <a
          style={item.lockedForCycleCount ? { color: RED } : {}}
          onClick={() =>
            self.setState({
              editDrawerLinkTarget: linkTargets.inventoryRecord,
              editDrawerLinkTargetText: `${t('inventory')} - ${text}`,
              editDrawerLinkTargetRecord: item,
              editDrawerVisible: true,
              editDrawerSaving: false,
            })
          }
        >
          {text}
        </a>
      </Tooltip>
    ),
    onHand: createOnHandTableCellComponent('onHand'),
    onHand2: createOnHandTableCellComponent('onHand2'),
    onOrder: createOnOrderTableCellComponent(false),
    lastCycleCount: createY2KNullTableCellComponent('lastCycleCount'),
    onHandStatus: (self, item) => {
      if (item.onHandStatus === 'GreaterThanMax') {
        return (
          <Tooltip title={item.onHandStatusInfo}>
            <Icon type="Speed" color={DARK_GREEN} />
          </Tooltip>
        )
      }

      if (item.onHandStatus === 'LessThanMin') {
        return (
          <Tooltip title={item.onHandStatusInfo}>
            <Icon type="Speed" color={RED} style={{ transform: 'scaleX(-1)' }} />
          </Tooltip>
        )
      }

      return null
    },
    totalOverstockOnHand: (self, item) => {
      if (item.totalOverstockOnHand === 0) {
        return <span className="mr-24">0</span>
      }

      return (
        <>
          <div>
            <a
              onClick={() => {
                if (self.hasUnsavedChanges()) {
                  message.error(t('saveChangesFirst'))
                } else {
                  self.setState({
                    overstockItemInventoryItem: item,
                    overstockItemInventorySelectedItem: null,
                    overstockItemInventoryLoading: false,
                    overstockItemInventoryVisible: true,
                    overstockItemInventoryTimestamp: Date.now(),
                  })
                }
              }}
              className="mr-6"
            >
              {item.totalOverstockOnHand}
            </a>
            <Popover
              title={t('overstockOnHand')}
              content={<span dangerouslySetInnerHTML={{ __html: item.overstockInventoryOnHandInfo }} />}
            >
              <span
                className="align-middle color-blue"
                style={{ visibility: isEmpty(item.overstockInventoryOnHandInfo) ? 'hidden' : 'visible' }}
              >
                <Icon type="Search" />
              </span>
            </Popover>
          </div>
        </>
      )
    },
    originCountryCode: (self, item, column, text) => getCountryName(item.originCountryCode, self.props.locale),
  },
  sharedComponents: (self) => (
    <>
      <Modal
        title={t('overstockItemInventory')}
        visible={self.state.overstockItemInventoryVisible}
        onCancel={() => self.setState({ overstockItemInventoryVisible: false })}
        footer={
          <>
            <Button onClick={() => self.setState({ overstockItemInventoryVisible: false })}>{t('close')}</Button>
            <Button
              onClick={() => self.setState({ transferOverstockInventoryVisible: true })}
              loading={self.state.overstockItemInventoryLoading}
              type="primary"
              disabled={isNil(self.state.overstockItemInventorySelectedItem)}
            >
              {t('transferToInventory')}
            </Button>
          </>
        }
        width={992}
      >
        <SelectOverstockItemInventory
          key={self.state.overstockItemInventoryTimestamp}
          initialFilterDto={{
            barcode: self.state.overstockItemInventoryItem?.barcode,
            locationId: self.state.overstockItemInventoryItem?.locationId,
          }}
          initialState={{
            sortBy: 'createdDate',
            sortOrder: 'ascend',
          }}
          onSelect={(values) => self.setState({ overstockItemInventorySelectedItem: values[0] })}
          filterProps={{
            disabled: true,
            barcode: self.state.overstockItemInventoryItem?.barcode,
            description: self.state.overstockItemInventoryItem?.itemDescription,
          }}
        />
      </Modal>
      <Modal
        title={`${t('transferToInventory')} - ${self.state.overstockItemInventorySelectedItem?.barcode}`}
        visible={self.state.transferOverstockInventoryVisible}
        onCancel={() => self.setState({ transferOverstockInventoryVisible: false })}
        okText={t('transfer')}
        onOk={() => Emitter.emit(FORM_SUBMIT_EVENT)}
        okButtonProps={{ loading: self.state.transferOverstockInventoryLoading }}
        width={750}
      >
        <TransferOverstockInventory
          parentRecord={self.state.overstockItemInventorySelectedItem}
          onSave={(pending) => self.promiseState({ transferOverstockInventoryLoading: pending })}
          onSaveAndClose={() =>
            self.setState(
              { transferOverstockInventoryVisible: false, overstockItemInventoryTimestamp: Date.now() },
              self.fetchItems
            )
          }
          onCancel={() => self.setState({ transferOverstockInventoryVisible: false })}
        />
      </Modal>
    </>
  ),
  deleteDisabled: (self) => getUserPermission('Inventory') !== 'Edit',
  createDisabled: (self) => getUserPermission('Inventory') !== 'Edit',
  getFileTemplateTypes: (self) => ['Inventory'],
  getRowClassName: (self) => (record, index) => {
    try {
      const { onHand, onHand2, onOrder, min } = record

      return onHand + onHand2 + onOrder < min ? 'table-row-warning' : ''
    } catch (error) {
      return ''
    }
  },
  totalsRowComponents: {
    totalOverstockOnHand: (self, item, column, text) => (
      <Tooltip title={t(column.totalCalculationType)}>
        <span style={{ marginRight: '22px' }}>{text}</span>
      </Tooltip>
    ),
  },
})(Filter)
